 
 <template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-row>
        <v-col class="mt-0 pt-0 pb-0 xs" cols="12" sm="12" md="12" lg="12">
          <v-card>
            <v-card-actions>
              <v-icon @click="close">mdi-arrow-left-circle</v-icon>

              <v-spacer></v-spacer>
              <v-chip class="ma-2">{{vm.status}}</v-chip>
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="pb-2" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-switch
                  v-if="this.vm.status !== 'Draft'"
                  v-model="completed"
                  true-value="Completed"
                  value="Completed"
                  :label="'Is complete: ' + (completed=='Completed')"
                  @change="completedChange"
                ></v-switch>
              </v-row>
              <v-row>
                <v-col class="wrap" cols="6">
                  <h4 class="my-0 " style="color:#156a59" >Goal details</h4>
                  <v-text-field
                    v-model="vm.title"
                    label="Title"
                    v-validate="'required'"
                    data-vv-as="title"
                    name="title"
                    :error-messages="errors.collect('title')"
                  ></v-text-field>
               
                  <v-textarea
                    v-model="vm.description"
                    label="Description"
                    filled
                    rows="4"
                    v-validate="'required'"
                    data-vv-as="description"
                    name="description"
                    :error-messages="errors.collect('description')"
                  ></v-textarea>
                </v-col>
          
                <v-col class="wrap" cols="6"  v-if="this.isBiller">
                  <h4 class="my-0 " style="color:#156a59" >Goal defaults</h4>
                  <v-btn @click="vm.budgetItem=null; vm.lineItem=null;"  x-small >Clear defaults</v-btn>
                  <v-select
                    v-model="vm.budgetItem"
                    :items="budgetItems"
                    label="Default budget item"
                    data-vv-as="budget item"
                    item-text="title"
                    name="budgetItem"
                    key="budgetItem"       
                    return-object
                    :error-messages="errors.collect('budgetItem')"
                  ></v-select>
                
                  
                  <v-select
                    v-if="vm.budgetItem"
                    v-model="vm.lineItem"
                    :items="vm.budgetItem.lineItems"                    
                    label="Line item"
                    data-vv-as="line item"
                    key="lineItem"
                    item-text="supportItem"
                    name="lineItem"    
                    return-object
                    :error-messages="errors.collect('lineItem')"
                  ></v-select>
                  <span v-if="vm.lineItem!==undefined && vm.lineItem!==null  && vm.lineItem.isOld!==undefined && vm.lineItem.isOld">
                    <v-icon color="orange darken-2">mdi-alert</v-icon>        
                    <span  class="orange--text text--darken-2">Using outdated price guide </span>             
                  </span>
                  <div v-if="vm.lineItem" class="caption">{{vm.lineItem.supportItemDescription}} ({{vm.lineItem.supportItemNumber}})</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-2" cols="12">
          <notes-list></notes-list>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as goalService from "../../../services/goalService";
Vue.use(VeeValidate);
import notesList from "./notes-list";
import * as lineItemService from "../../../services/lineItemSevice";
import * as budgetItemService from "../../../services/budgetItemService";
export default {
  name: "goals",

  data: () => ({
    vm: { status: "Draft", isDeleted: false },
    valid: true,
    budgetItems:[],
    loading: false,
    completed: "",
    isBiller: false,
  }),
  props: {
    msg: String
  },
  components: {
    "notes-list": notesList
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentGoal() {
      return this.$store.getters.currentGoal;
    }
  },
  methods: {
    init() {
      const me = this;
      this.vm = { status: "Draft", isDeleted: false };
      if (this.currentGoal !== undefined && this.currentGoal !== null) {
        this.vm = this.currentGoal;
      }
      this.completed = this.vm.status;
      lineItemService
        .getById(this.currentCase.planDetails.lineItemDefinitionId)
        .then(result => {
          this.lineItemDefinition = result.data;
        });


        this.isBiller = false;

      if (
        this.user.roles.indexOf("Billing") > -1 ||
        this.user.roles.indexOf("System Administrator") > -1 ||
        this.user.roles.indexOf("Organisation Administrator") > -1
      ) {
        this.isBiller = true;

         budgetItemService
          .list(me.currentOrganisation.id,me.currentCase.id)
          .then(querySnapshot => {
            me.budgetItems = querySnapshot.docs.map(d => d.data());
          
          });
      }

      
    },

    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          let me = this;
          if (me.vm.status == undefined) {
            me.vm.status = "Draft";
            me.vm.isDeleted = false;
          }
          goalService
            .save(me.currentOrganisation.id, me.currentCase.id, me.vm)
            .then(ref => {
              if (ref != undefined) {
                me.vm.id = ref.id;
              }
              me.$store.commit("setCurrentGoal", me.vm);

              me.$store.dispatch("setToast", {
                message: "Goal saved",
                color: "primary"
              });
              
            })
            .catch(error => {
              console.error("Error saving goal: ", error);
            });
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    close() {
      this.$router.push("/goals");
    },
    next() {
      this.$emit("next-step");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    completedChange() {
      const me = this;
      if (this.completed == "Completed") {
        this.vm.status = "Completed";
      } else {
        this.vm.status = "In Progress";
      }
      goalService
        .save(me.currentOrganisation.id, me.currentCase.id, me.vm)
        .then(ref => {
          if (ref != undefined) {
            me.vm.id = ref.id;
          }
          me.$store.commit("setCurrentGoal", me.vm);

          me.$store.dispatch("setToast", {
            message: "Event saved",
            color: "primary"
          });
        })
        .catch(error => {
          console.error("Error saving event: ", error);
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

